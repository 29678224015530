<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <el-form :rules="rules" v-loading="loading" ref="form" :model="user" label-width="12rem">
    <el-link type="primary" :underline="false" class="mt-1"
             @click="$router.go(-1)"><i class="el-icon-back" /> Назад</el-link>
      <template v-if="user">
        <div class="mt-3" v-if="userStatusFirstAuth">До начала работы с системой необходимо проверить учетные данные и сменить пароль</div>
        <el-row :gutter="25">
          <el-col :span="8">
            <el-form-item prop="organizationName" label="Организация:">
              <el-input disabled v-model="user.organizationName"></el-input>
            </el-form-item>
            <el-form-item prop="email" label="E-mail">
              <el-input v-model="user.email"></el-input>
            </el-form-item>
            <el-form-item style="text-align: left" prop="timeZone" label="Часовой пояс:">
              <el-select style="width: 100%" v-model="user.timeZone">
                <el-option
                  v-for="item in timeZones"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" class="change-password">
            <el-form-item prop="userName" label="Логин:">
              <el-input disabled aria-placeholder="Username" placeholder="Username" autocomplete="username" v-model="user.userName"></el-input>
            </el-form-item>
            <el-form-item prop="RoleGroupName" label="Роль:">
              <el-input disabled aria-placeholder="RoleGroupName" placeholder="" v-model="userRoleGroups"></el-input>
            </el-form-item>
            <el-form-item class="mb-0" label="" v-if="!userIsActiveDirectory">
              <el-checkbox v-if="!userStatusFirstAuth" v-model="needChangePassword" @change="needChangePasswordCheckHandle">Смена пароля</el-checkbox>
            </el-form-item>
            <el-form-item prop="passwordOld" label="Текущий пароль:" :model="user.passwordOld" v-if="!userIsActiveDirectory">
              <el-input :disabled="!needChangePassword"
                        id="passwordOld"
                        v-model="user.passwordOld"
                        type="password" aria-placeholder="Password" autocomplete="password"
                        prefix-icon="el-icon-lock">
                <el-button slot="append" @click="viewPassword('passwordOld')">
                  <i v-if="passwordOldIsView" class="el-icon-view"></i>
                  <i v-if="!passwordOldIsView"> <close-eye-icon /> </i>
                </el-button>
              </el-input>
            </el-form-item>
            <el-form-item prop="passwordNew" label="Новый пароль:" :model="user.passwordNew" v-if="!userIsActiveDirectory">
              <el-input :disabled="!needChangePassword"
                        v-model="user.passwordNew"
                        id="passwordNew"
                        type="password"
                        prefix-icon="el-icon-lock">
                <el-button slot="append" @click="viewPassword('passwordNew')">
                  <i v-if="passwordNewIsView" class="el-icon-view"></i>
                  <i v-if="!passwordNewIsView"> <close-eye-icon /> </i>
                </el-button>
              </el-input>
            </el-form-item>
            <el-form-item prop="passwordRepeat" label="Повторите пароль:" :model="user.passwordRepeat" v-if="!userIsActiveDirectory">
              <el-input :disabled="!needChangePassword"
                        v-model="user.passwordRepeat"
                        id="passwordRepeat"
                        type="password"
                        prefix-icon="el-icon-lock">
                <el-button slot="append" @click="viewPassword('passwordRepeat')">
                  <i v-if="passwordRepeatIsView" class="el-icon-view"></i>
                  <i v-if="!passwordRepeatIsView"> <close-eye-icon /> </i>
                </el-button>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col  v-if="!userIsActiveDirectory" :span="8">
            <el-form-item prop="registrationOrganizationName" label="Диспетчерский центр:">
              <el-input disabled v-model="user.registrationOrganizationName"></el-input>
            </el-form-item>
            <div v-if="user.registrationOrganizationId" prop="сontactEmails" >
              <label class="el-form-item__label" style="width: 100%; line-height: 1.5;">Адрес(а) электронной почты ответственных сотрудников СРЗА ДЦ:</label>
              <el-tag
                v-for="tag in user.contactEmails"
                :key="tag"
                class="mx-1"
                :disable-transitions="false"
                v-if="tag !== null"
              >
                {{ tag  }}
              </el-tag>
            </div>
          </el-col>
        </el-row>
        <div class="mt-0">
          <el-button
            :disabled="loading"
            class="login-button mt-4"
            type="primary"
            @click="handleUpdateUser"
          >Сохранить</el-button>
        </div>
      </template>
  </el-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import closeEyeIcon from '@/assets/icons/close-eye.svg';
import validationRules from '@/mixins/validationRules';
import identityApi from '@/api/identity';
// import email from '../../api/email';

export default {
  name: 'UserInfo',
  mixins: [validationRules],
  components: { closeEyeIcon },
  async mounted() {
    this.user = { ...this.$store.getters['identity/user'] }; // clone
    if (!this.user || !this.user.id) {
      await this.fetchUser();
      this.user = { ...this.$store.getters['identity/user'] }; // clone
    }

    if (this.userStatusFirstAuth) {
      this.needChangePassword = true;
      this.needChangePasswordCheckHandle(true);
      this.rules.passwordOld.push({ validator: this.validateSamePassword, trigger: 'blur' });
    }
  },
  data() {
    return {
      rules: {
        userName: [
          {
            required: true,
            message: 'Необходимо указать логин',
            trigger: 'blur',
          },
          {
            max: 50, message: 'до 50 символов', trigger: 'blur'
          }
        ],
        passwordOld: [
          {
            required: false,
            message: 'Необходимо указать пароль',
            trigger: 'blur',
          },
          /* { validator: this.validatePassword, trigger: 'blur' } */
        ],
        passwordNew: [
          {
            required: false,
            message: 'Необходимо указать новый пароль',
            trigger: 'blur',
          },
          { validator: this.validatePassword, trigger: 'blur' }
        ],
        passwordRepeat: [
          {
            required: false,
            message: 'Необходимо указать новый пароль',
            trigger: 'blur',
          },
          { validator: this.validateRepeatPassword, trigger: 'blur' }
        ],
      },
      user: null,
      loading: false,
      needChangePassword: false,
      passwordRepeatIsView: false,
      passwordNewIsView: false,
      passwordOldIsView: false,
    };
  },
  computed: {
    ...mapGetters('dictionaries', ['timeZones']),
    /* ...mapGetters('identity', ['user']), */
    userStatusFirstAuth() {
      return this.user.statusId === 1;
    },

    userIsActiveDirectory() {
      return this.user.isActiveDirectory;
    },

    userRoleGroups() {
      let result = '';
      if (!this.user || !this.user.roleGroups || !this.user.roleGroups.length) {
        return result;
      }
      for (let i = 0; i < this.user.roleGroups.length; i++) {
        if (i > 0) {
          result += '; ';
        }
        result += this.user.roleGroups[i].name;
      }
      return result;
    }
  },
  methods: {
    ...mapActions('identity', [
      'fetchUser', 'logout'
    ]),
    needChangePasswordCheckHandle(newVal) {
      this.rules.passwordOld[0].required = newVal;
      this.rules.passwordRepeat[0].required = newVal;
      this.rules.passwordNew[0].required = newVal;

      if (!newVal) {
        this.user.passwordNew = null;
        this.user.passwordRepeat = null;
      }
    },
    viewPassword(element) {
      switch (document.getElementById(element).type) {
        case 'text':
          document.getElementById(element).type = 'password';
          break;
        case 'password':
          document.getElementById(element).type = 'text';
          break;
        default:
          document.getElementById(element).type = 'password';
          break;
      }
      switch (element) {
        case 'passwordOld':
          this.passwordOldIsView = !this.passwordOldIsView;
          break;
        case 'passwordNew':
          this.passwordNewIsView = !this.passwordNewIsView;
          break;
        case 'passwordRepeat':
          this.passwordRepeatIsView = !this.passwordRepeatIsView;
          break;
        default:
          break;
      }
    },
    validateRepeatPassword(rule, value, callback) {
      if (value !== this.user.passwordNew) {
        callback(new Error('Пароли не совпадают'));
      }
      callback();
    },
    validateSamePassword(rule, value, callback) {
      if (value === this.user.passwordNew) {
        callback(new Error('Пароли не должны совпадать'));
      }
      callback();
    },
    async handleUpdateUser() {
      const valid = await this.$refs.form.validate();
      if (!valid) {
        return;
      }

      if (!this.user.email) {
        this.$alert('Введите электронную почту');
        return;
      }

      const message = '<span>Вы действительно хотите изменить данные?</span>';
      this.$confirm(message,
        'Подтверждение', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: 'ОК',
          cancelButtonText: 'Отмена',
          type: 'warning',
        }).then(async () => {
        await this.updateUser();
      });
    },
    async updateUser() {
      this.loading = true;
      const response = await identityApi.updateCurrentUser({
        id: this.user.id,
        email: this.user.email,
        timeZone: this.user.timeZone,
        userName: this.user.userName,
        needChangePassword: this.needChangePassword,
        passwordOld: this.needChangePassword ? this.user.passwordOld : null,
        passwordNew: this.needChangePassword ? this.user.passwordNew : null,
      });

      if (response && response.data) {
        if (!response.data.succeeded) {
          this.$alert(response.data.resultMessage, 'Изменение данных пользователя', {
            confirmButtonText: 'OK',
            type: 'warning',
            customClass: 'alert-30',
          });
        } else {
          this.$alert(response.data.resultMessage, 'Изменение данных пользователя', {
            confirmButtonText: 'OK',
            type: 'success',
            customClass: 'alert-30',
            showClose: false,
            // callback: this.userUpdateCallback
          });

          if (this.userStatusFirstAuth) {
            this.logout();
            this.$router.push({ path: '/login' });
          } else {
            await this.fetchUser();
            this.user = { ...this.$store.getters['identity/user'] }; // clone
          }
        }
      }

      this.loading = false;
    },
  }
};
</script>

<style scoped>
h2 {
  margin-top: 0px;
}

.change-password__form .el-input{
  width: 60%;
}

.link_action_panel{
  display: flex;
  justify-content: space-between;
}
</style>
